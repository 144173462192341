
import { EventDispatcher } from "../application/EventDispatcher";
import { MessageClient } from "./MessageClient";
import { ViewerState } from "../application/ViewerState";
import * as et from "../application/EventTypes";
import { GlobalManagerMixin } from "../application/GlobalManagerMixin";


export function LiveReviewClient(viewer) {

  this.viewer = viewer;
  this.setGlobalManager(viewer.globalManager);
  this.messageClient = null;
  this.presenceChannelId = null;
  this.viewtx = null;
  this.interceptor = null;
};

GlobalManagerMixin.call(LiveReviewClient.prototype);

LiveReviewClient.prototype.destroy = function () {
  this.leaveLiveReviewSession();
};

LiveReviewClient.prototype.joinLiveReviewSession = function (sessionId) {

  if (!this.messageClient)
  this.messageClient = MessageClient.GetInstance();
  if (!this.presenceChannelId)
  this.presenceChannelId = this.getWindow().location.host;
  if (!this.messageClient.isConnected()) {
    this.messageClient.connect(sessionId);
  }

  if (!this.viewtx) {
    this.viewtx = new ViewTransceiver(this.messageClient);
    this.viewtx.setGlobalManager(this.globalManager);
  }
  this.viewtx.channelId = sessionId;
  this.viewtx.attach(this.viewer);

  this.messageClient.join(this.viewtx.channelId);

  if (!this.interceptor)
  this.interceptor = new InteractionInterceptor(this.viewtx);
  this.viewer.toolController.registerTool(this.interceptor);
  this.viewer.toolController.activateTool(this.interceptor.getName());
};

LiveReviewClient.prototype.leaveLiveReviewSession = function () {
  this.viewtx && this.viewtx.detach(this.viewer);
  this.messageClient && this.messageClient.disconnect();
  if (this.interceptor) {
    this.viewer.toolController.deactivateTool(this.interceptor.getName());
  }

  this.viewtx = null;
  this.messageClient = null;
  this.interceptor = null;
};



export function InteractionInterceptor(viewtx) {

  this.getNames = function () {
    return ["intercept"];
  };

  this.getName = function () {
    return "intercept";
  };

  this.activate = function (name) {};
  this.deactivate = function (name) {};
  this.update = function (timeStamp) {return false;};

  this.handleSingleClick = function (event, button) {return false;};
  this.handleDoubleClick = function (event, button) {return false;};
  this.handleSingleTap = function (tap) {return false;};
  this.handleDoubleTap = function (tap1, tap2) {return false;};
  this.handleKeyDown = function (event, keyCode) {return false;};
  this.handleKeyUp = function (event, keyCode) {return false;};

  this.handleWheelInput = function (delta) {
    viewtx.takeControl();
    return false;
  };

  this.handleButtonDown = function (event, button) {
    viewtx.takeControl();
    return false;
  };

  this.handleButtonUp = function (event, button) {return false;};
  this.handleMouseMove = function (event) {
    viewtx.updatePointer(event);
    return false;
  };

  this.handleGesture = function (event) {
    viewtx.takeControl();
    return false;
  };

  this.handleBlur = function (event) {return false;};
  this.handleResize = function () {};
}

export function ViewTransceiver(client) {

  var _this = this;
  var _viewer = this.viewer = null;
  var _blockEvents = false;
  var _haveControl = false;
  var _isDisconnected = false;
  var _lastInControl;
  var _client = this.client = client;
  var _ray = new THREE.Ray();
  var _pointer = null;
  var _pointerOn = false;

  this.channelId = null;

  var _viewerState;
  var VIEWER_STATE_FILTER = {
    seedURN: false,
    objectSet: true,
    viewport: false,
    cutplanes: true,
    renderOptions: {
      environment: false,
      ambientOcclusion: false,
      toneMap: {
        exposure: false },

      appearance: false } };




  function onViewerState(evt) {
    _blockEvents = true;
    var state = JSON.parse(evt.data.msg);
    _viewerState.restoreState(state);
    _viewer.impl.invalidate(true, false, true);
    _blockEvents = false;
  }

  function reduceBits(v) {
    return Math.round(v * 1000) / 1000;
  }

  function reduceBitsV(v) {
    for (var i = 0; i < v.length; i++) {
      v[i] = reduceBits(v[i]);}
  }

  function onCamera(e) {
    var v = e.data.msg;

    if (v[1] === true || _isDisconnected)
    {
      return;
    }

    if (v[0] != _lastInControl)
    {
      _lastInControl = v[0];
      e.data.lastInControl = v[0];
      _this.dispatchEvent({ type: "controlChange", channelId: _this.channelId, data: e.data });
    }

    //For now, automatically relinquish camera control if we receive a remote command to move the camera
    _haveControl = false;

    /*
                           viewer.navigation.setRequestTransitionWithUp(true, new THREE.Vector3().set(v[1+0],v[1+1],v[1+2]),
                           new THREE.Vector3().set(v[1+3],v[1+4],v[1+5]),
                           _viewer.navigation.getCamera().fov,
                           new THREE.Vector3().set(v[1+6],v[1+7],v[1+8]));
                           */

    _viewer.navigation.setView(new THREE.Vector3().set(v[2 + 0], v[2 + 1], v[2 + 2]),
    new THREE.Vector3().set(v[2 + 3], v[2 + 4], v[2 + 5]));
    _viewer.navigation.setCameraUpVector(new THREE.Vector3().set(v[2 + 6], v[2 + 7], v[2 + 8]));
  }

  function sendCamera(evt) {
    if (!_haveControl && !_isDisconnected)
    return;

    var c = evt.camera;
    var camParams = [c.position.x, c.position.y, c.position.z,
    c.target.x, c.target.y, c.target.z,
    c.up.x, c.up.y, c.up.z];


    reduceBitsV(camParams);
    camParams.unshift(_isDisconnected);
    camParams.unshift(client.getLocalId());

    _client.sendMessage("camera", camParams, _this.channelId);

    if (_lastInControl != camParams[0]) {
      _lastInControl = camParams[0];
      _this.dispatchEvent({ type: "controlChange", channelId: _this.channelId, data: { lastInControl: _lastInControl } });
    }
  }


  function showPointer(show, x, y) {

    var _document = _this.getDocument();
    if (show && !_pointer) {
      _pointer = _document.createElement("div");
      _pointer.classList.add("collabPointer");
    }

    if (show && !_pointerOn) {
      _viewer.container.appendChild(_pointer);
      _pointerOn = true;
    } else
    if (!show && _pointerOn) {
      _viewer.container.removeChild(_pointer);
      _pointerOn = false;
    }

    if (show) {
      //Note the 4px is half the width/height specified in the CSS,
      //so that the pointer is centered.
      _pointer.style.left = x - 6 + "px";
      _pointer.style.top = y - 6 + "px";
    }

  }

  function onPointer(e) {

    if (_haveControl)
    return; //shouldn't get here in theory, but let's check just in case

    if (_isDisconnected)
    return; //we can't show the pointer if the views don't match

    var v = e.data.msg;
    _ray.origin.set(v[1], v[2], v[3]);
    _ray.direction.set(v[4], v[5], v[6]);

    var pt = _ray.at(_viewer.getCamera().near);
    pt.project(_viewer.getCamera());

    pt = _viewer.impl.viewportToClient(pt.x, pt.y);

    //logger.log(pt.x + " " + pt.y);
    showPointer(true, pt.x, pt.y);
  }


  function sendPointer(evt) {
    if (!_haveControl)
    return;

    //Note canvasX/Y are set by the ToolController to clientX/Y - canvas left/top.
    var vpVec = _viewer.impl.clientToViewport(evt.canvasX, evt.canvasY);
    _viewer.impl.viewportToRay(vpVec, _ray);

    var rayParams = [_ray.origin.x, _ray.origin.y, _ray.origin.z,
    _ray.direction.x, _ray.direction.y, _ray.direction.z];

    reduceBitsV(rayParams);
    rayParams.unshift(client.getLocalId());

    _client.sendMessage("pointer", rayParams, _this.channelId);
  }


  function sendViewerState(e) {
    //if (!_haveControl)
    //    return;
    if (_blockEvents)
    return;

    var state = _viewerState.getState(VIEWER_STATE_FILTER);

    // TODO: if we kill the socket.io code path, this could be optimized
    // too by removing the JSON.stringify of the state. Pubnub automatically
    // does JSON serialization for us, with optimizations accordingly to their manual.
    client.sendMessage("state", JSON.stringify(state), _this.channelId);
  }


  this.takeControl = function () {
    _haveControl = true;
    showPointer(false);
  };

  this.updatePointer = function (e) {
    sendPointer(e);
  };

  this.connectCamera = function (set) {
    _isDisconnected = !set;
  };

  this.attach = function (viewer, skipStateTracking) {

    if (_viewer)
    this.detach();

    this.viewer = _viewer = viewer;
    _viewerState = new ViewerState(_viewer);

    _client.addEventListener("cameraChange", onCamera);
    _client.addEventListener("pointerMove", onPointer);

    if (!_viewer.hasEventListener(et.CAMERA_CHANGE_EVENT, sendCamera))
    _viewer.addEventListener(et.CAMERA_CHANGE_EVENT, sendCamera);

    if (!skipStateTracking) {
      _client.addEventListener("viewerState", onViewerState);

      if (!_viewer.hasEventListener(et.SELECTION_CHANGED_EVENT, sendViewerState)) {
        _viewer.addEventListener(et.SELECTION_CHANGED_EVENT, sendViewerState);
        _viewer.addEventListener(et.ISOLATE_EVENT, sendViewerState);
        _viewer.addEventListener(et.HIDE_EVENT, sendViewerState);
        _viewer.addEventListener(et.SHOW_EVENT, sendViewerState);
        _viewer.addEventListener(et.EXPLODE_CHANGE_EVENT, sendViewerState);
        _viewer.addEventListener(et.LAYER_VISIBILITY_CHANGED_EVENT, sendViewerState);
        _viewer.addEventListener(et.CUTPLANES_CHANGE_EVENT, sendViewerState);
      }
    }
  };


  this.detach = function () {

    showPointer(false);

    if (_client) {
      _client.removeEventListener("cameraChange", onCamera);
      _client.removeEventListener("viewerState", onViewerState);
    }

    if (_viewer) {
      _viewer.removeEventListener(et.CAMERA_CHANGE_EVENT, sendCamera);

      _viewer.removeEventListener(et.SELECTION_CHANGED_EVENT, sendViewerState);
      _viewer.removeEventListener(et.ISOLATE_EVENT, sendViewerState);
      _viewer.removeEventListener(et.HIDE_EVENT, sendViewerState);
      _viewer.removeEventListener(et.SHOW_EVENT, sendViewerState);
      _viewer.removeEventListener(et.EXPLODE_CHANGE_EVENT, sendViewerState);
      _viewer.removeEventListener(et.LAYER_VISIBILITY_CHANGED_EVENT, sendViewerState);
      _viewer.removeEventListener(et.CUTPLANES_CHANGE_EVENT, sendViewerState);

      this.viewer = _viewer = null;
      _viewerState = null;
    }
  };

}

ViewTransceiver.prototype.constructor = ViewTransceiver;
EventDispatcher.prototype.apply(ViewTransceiver.prototype);
GlobalManagerMixin.call(ViewTransceiver.prototype);