
import { SceneMath } from "../wgs/scene/SceneMath";

/**
                                                     * Used in order to convert from Leaflet coordinates to PDF coordinates.
                                                     */
function leafletToPdfWorld(viewer, point) {
  var leafletNormalizingMatrix = getLeafletNormalizingMatrix(viewer);

  if (!leafletNormalizingMatrix) {
    return null;
  }

  point.applyMatrix4(leafletNormalizingMatrix);

  var pdfNormalizingMatrix;

  // In case the model is Leaflet, generate bbox from the metadata.
  if (viewer.model.isLeaflet()) {
    var dimensions = viewer.model.getMetadata('page_dimensions');
    var bbox = new THREE.Box3(new THREE.Vector3(), new THREE.Vector3(dimensions.page_width, dimensions.page_height, 0));
    pdfNormalizingMatrix = SceneMath.getNormalizingMatrix(undefined, bbox);
  } else {
    // Otherwise it's a vector-PDF - just take the bounding box from the model itself.
    pdfNormalizingMatrix = SceneMath.getNormalizingMatrix(viewer.model);
  }

  pdfNormalizingMatrix = pdfNormalizingMatrix.getInverse(pdfNormalizingMatrix);
  point.applyMatrix4(pdfNormalizingMatrix);

  return point;
}

/**
   * Used in order to convert from PDF coordinates to Leaflet coordinates.
   * 
   * Assumes the current model is loaded with PDFLoader.
   */
function pdfToLeafletWorld(viewer, point) {
  var pdfNormalizingMatrix = SceneMath.getNormalizingMatrix(viewer.model);
  point.applyMatrix4(pdfNormalizingMatrix);

  var leafletNormalizingMatrix = getLeafletNormalizingMatrix(viewer);

  if (!leafletNormalizingMatrix) {
    return null;
  }

  leafletNormalizingMatrix = leafletNormalizingMatrix.getInverse(leafletNormalizingMatrix);
  point.applyMatrix4(leafletNormalizingMatrix);

  return point;
}

/**
   * Searches inside the bubble for the Leaflets params.
   */
function getLeafletLoadOptions(viewer) {var _documentNode$search$;
  var documentNode = viewer.impl.model.getDocumentNode();

  var leafletItem = documentNode === null || documentNode === void 0 ? void 0 : (_documentNode$search$ = documentNode.search(Autodesk.Viewing.BubbleNode.LEAFLET_NODE)[0]) === null || _documentNode$search$ === void 0 ? void 0 : _documentNode$search$._raw();

  if (!leafletItem) {
    return null;
  }

  var options = {};
  var _document = new Autodesk.Viewing.Document(documentNode.getRootNode()._raw(), '');
  _document.getLeafletParams(options, documentNode, leafletItem);

  return options;
}

/**
   * Calculates the Leaflet's bounding box, using parameters from the bubble.
   * Use the same original logic from the leaflet loader.
   */
function getLeafletBoundingBox(viewer) {
  var texQuadConfig = new Autodesk.Viewing.Private.TexQuadConfig();
  var options = getLeafletLoadOptions(viewer);

  if (!options) {
    return null;
  }

  texQuadConfig.initFromLoadOptions(null, options);
  var boundingBox = texQuadConfig.getBBox();

  return boundingBox;
}

function getLeafletNormalizingMatrix(viewer) {
  var bbox = getLeafletBoundingBox(viewer);

  if (!bbox) {
    return null;
  }

  var matrix = SceneMath.getNormalizingMatrix(null, bbox);

  return matrix;
}


export var PDFUtils = {
  leafletToPdfWorld: leafletToPdfWorld,
  pdfToLeafletWorld: pdfToLeafletWorld,
  getLeafletLoadOptions: getLeafletLoadOptions,
  getLeafletBoundingBox: getLeafletBoundingBox,
  getLeafletNormalizingMatrix: getLeafletNormalizingMatrix };