import { endpoint } from "../file-loaders/net/endpoints";

export function getLoadModelData(url, lmvFileExtension, returnValue, node) {
  var global = Autodesk.Viewing.getGlobal();
  var dataToTrack = {
    url: url,
    lmvFileExtension: lmvFileExtension,
    returnValue: returnValue };


  if (node) {
    dataToTrack['isOtg'] = node.isOtg() && endpoint.isOtgBackend();
    dataToTrack['isSVF2'] = node.isSVF2() && endpoint.isSVF2Backend();
    dataToTrack['geometrySize'] = node.data.size || 0;

    // seed file info
    var viewable = node.findViewableParent();
    try {
      var name = viewable && viewable.name();
      var pos = name && name.lastIndexOf('.');
      var ext = pos >= 0 && name.substring(pos + 1);
      if (ext) {
        dataToTrack['seedFileExt'] = ext.toLowerCase();
      }

    } catch (e) {
    }

  }

  return dataToTrack;
}