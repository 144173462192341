import { GlobalManager } from './GlobalManager';
var globalManager = new GlobalManager();

function GlobalManagerMixin() {
  this.globalManager = globalManager;

  this.setGlobalManager = function (globalManager) {
    this.globalManager = globalManager;
    this.onSetGlobalManager(this.globalManager);
  };

  /**
      * Classes can override this method to pass the instance to other objects
      * See DockingPanel.js for an example
      * @param {GlobalManager} globalManager GlobalManager instance
      */
  // eslint-disable-next-line no-unused-vars
  this.onSetGlobalManager = function (globalManager) {
    // To be overridden
  };

  this.getWindow = function () {
    return this.globalManager.getWindow();
  };

  this.getDocument = function () {
    return this.globalManager.getDocument();
  };

  this.setWindow = function (newWindow) {
    return this.globalManager.setWindow(newWindow);
  };

  this.addWindowEventListener = function (type, listener) {var _this$globalManager;for (var _len = arguments.length, options = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {options[_key - 2] = arguments[_key];}
    (_this$globalManager = this.globalManager).addWindowEventListener.apply(_this$globalManager, [type, listener].concat(options));
  };

  this.removeWindowEventListener = function (type, listener) {var _this$globalManager2;for (var _len2 = arguments.length, options = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {options[_key2 - 2] = arguments[_key2];}
    (_this$globalManager2 = this.globalManager).removeWindowEventListener.apply(_this$globalManager2, [type, listener].concat(options));
  };

  this.addDocumentEventListener = function (type, listener) {var _this$globalManager3;for (var _len3 = arguments.length, options = new Array(_len3 > 2 ? _len3 - 2 : 0), _key3 = 2; _key3 < _len3; _key3++) {options[_key3 - 2] = arguments[_key3];}
    (_this$globalManager3 = this.globalManager).addDocumentEventListener.apply(_this$globalManager3, [type, listener].concat(options));
  };

  this.removeDocumentEventListener = function (type, listener) {var _this$globalManager4;for (var _len4 = arguments.length, options = new Array(_len4 > 2 ? _len4 - 2 : 0), _key4 = 2; _key4 < _len4; _key4++) {options[_key4 - 2] = arguments[_key4];}
    (_this$globalManager4 = this.globalManager).removeDocumentEventListener.apply(_this$globalManager4, [type, listener].concat(options));
  };

}

Autodesk.Viewing.GlobalManager = GlobalManager;
Autodesk.Viewing.GlobalManagerMixin = GlobalManagerMixin;

export { GlobalManagerMixin };