"use strict";function _typeof(obj) {"@babel/helpers - typeof";if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {_typeof = function _typeof(obj) {return typeof obj;};} else {_typeof = function _typeof(obj) {return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;};}return _typeof(obj);}

import { getGlobal } from "../compat";
import { i18n } from "./i18next";
import { Lang } from "./langs";

export var extendLocalization = function extendLocalization(locales) {
  if (locales !== null && _typeof(locales) === "object") {
    Object.keys(locales).forEach(function (language) {
      i18n.addResourceBundle(
      language,
      "allstrings",
      locales[language],
      true,
      true);

    });
    return true;
  }
  return false;
};

export var setLanguage = function setLanguage(language, callback) {

  var options = {
    lng: language,
    resGetPath: 'res/locales/__lng__/__ns__.json',
    ns: {
      namespaces: ['allstrings'],
      defaultNs: 'allstrings' },

    fallbackLng: "en",
    debug: false,
    useCookie: false };


  getGlobal().LOCALIZATION_REL_PATH = "res/locales/" + language + "/";
  i18n.init(options, function (t) {
    i18n.clearDebugLocString(); //Calls localize as well
    if (callback) {
      callback();
    }
  });
};


/**
    * Initialize language for localization. The corresponding string files will get downloaded.
    */
export var initializeLocalization = function initializeLocalization(options) {
  var language = options && options.language || navigator.language;
  var lang = Lang.getSupported(language);
  setLanguage(lang);
};